<template>
    <div class="timer-container" @click="$router.push({ name: 'TimersProduct' })">
        <div class="header-timer">
            PRODUCTOS
            <button @click="$router.push({ name: 'TimersProduct', query: { create: 'true' } })">
                <img src="img/add-icon.svg" alt="trash" width="69%" />
            </button>
        </div>
        <div class="container-list">
            <div class="timer-list details-products" :class="{ 'display-center': !existAlert || isOffline }">
                <template v-if="isOffline">
                    <img src="img/offline.svg" alt="offline" width="16%" />
                </template>

                <template v-else>
                    <template v-if="existAlert">
                        <div class="container-trash">
                            <div class="trash">
                                <img src="img/trash.svg" alt="trash" width="79%" />
                            </div>
                            <div class="details-products">
                                <label>
                                    <span class="count-discard">{{ timerproduct['to_delete'] }}</span>
                                    <br />
                                    <span>A DESECHAR</span>
                                </label>
                            </div>
                        </div>
                        <div class="container-trash">
                            <div class="clock">
                                <img src="img/clock-circular-outline.svg" alt="clock" width="70%" />
                            </div>
                            <div class="details-products">
                                <label>
                                    <span class="count-timer">{{ timerproduct['without_timer'] }}</span>
                                    <br />
                                    <span>SIN TIMER</span>
                                </label>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <img src="img/andy-happy.svg" alt="andy-happy" width="20%" />
                        <label class="without-alerts">Sin alertas</label>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardProductTimers',
    computed: {
        timerproduct() {
            return this.$store.getters['timerproduct/getHomeWidgetData']
        },
        existAlert() {
            return this.timerproduct['to_delete'] > 0 || this.timerproduct['without_timer'] > 0
        },
        isOffline() {
            return !fnCheckConnection()
        }
    },
    methods: {
        count(param) {
            let store = this.$store.getters['timerproduct/getHomeWidgetData']

            return store[param]
        }
    }
}
</script>

<style lang="scss" scoped>
.timer-container {
    width: 100%;
    min-width: 700px;
    margin: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
}

.header-timer {
    background-color: #e0e0e0;
    padding: 20px 0px;
    position: relative;
    font-family: $title-bold;
    text-align: center;
    font-size: 18px;
}

.header-timer button {
    background-color: #1ba844;
    padding: 6px;
    border-radius: 4px;
    position: absolute;
    right: 17px;
    bottom: 10px;
    width: 40px;
    height: 40px;
}

.container-list {
    padding: 22px 15px;
    height: calc(100% - 60px);
}

.timer-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.display-center {
    justify-content: center;
    align-items: center;
}

.container-trash {
    display: flex;
    height: 47%;
}

.trash {
    background-color: #bd1f1e;
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.clock {
    background-color: #e2e2e2;
    padding: 0px 7.4px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.details-products {
    margin-left: 20px;
}

.details-products label span:first-child {
    font-size: 65px;
    font-family: $title-bold;
}

.details-products label span:last-child {
    color: #757575;
    font-size: 21px;
    font-family: $text-light;
}

.details-products label {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}
.count-discard {
    color: #bd1f1e;
}

.count-timer {
    color: #757575;
}

.without-alerts {
    font-size: 18px;
    font-weight: 600;
    color: #2e2d2d;
}
</style>
