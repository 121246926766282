<template>
    <div class="timer-container" @click="$router.push({ name: 'Timers' })">
        <div class="header-timer">TIMERS OPERACIONALES</div>
        <template v-if="isOffline">
            <div class="offline">
                <img src="/img/offline/no_wifi.svg" alt="offline" width="15%" />
            </div>
        </template>
        <template v-else>
            <div
                class="timer-summary"
                :class="{
                    'bg-error': existsPendingTimers,
                    'bg-success': !existsPendingTimers
                }"
            >
                <img v-if="!existsPendingTimers" src="img/andy-happy.svg" alt="andy-happy" width="20%" />

                <div v-else class="circle">
                    <span class="count">{{ timerPendings.length }}</span>
                </div>

                <div class="pending-text">
                    <template v-if="existsPendingTimers">
                        Timers <br />
                        pendientes
                    </template>
                    <template v-else> Sin alertas </template>
                </div>
            </div>
            <div class="container-list">
                <div class="timer-list">
                    <div v-for="product in existsPendingTimers ? timerPendings : timersCompleted" :key="product.id" class="timer-item">
                        <div class="task">
                            <img :src="!existsPendingTimers ? 'img/greeting-hand.svg' : 'img/check-circle-red.svg'" alt="greeting-hand" />
                            <span class="task-description">
                                {{ product.name }}
                            </span>
                        </div>
                        <div v-if="existsPendingTimers" class="time">{{ convertTimestampToTime(product.currentPendingAlarm) }}h</div>
                        <div v-else class="time">
                            <template v-if="product.isAvailable">
                                {{ convertTimestampToTime(product.currentPendingAlarm) }}</span>
                            </template>
                            <template v-else>
                                {{ convertTimestampToTime(product.nextAlarm) }}</span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'CardOperationalTimers',
    computed: {
        timerPendings() {
            return this.$store.getters['timer/getTimersPendings']
        },
        timersCompleted() {
            return this.$store.getters['timer/getTimersCompleted'].slice(0,3)
        },
        existsPendingTimers() {
            return this.timerPendings.length > 0
        },
        isOffline() {
            return !fnCheckConnection()
        }
    },
    methods: {
        convertTimestampToTime(timestamp) {
            let date = moment(timestamp)
            if( date.isAfter(moment().endOf('day')) ) {
                return date.format("MMM DD HH:mm")
            }
            return moment(timestamp).format("HH:mm")
        }
    }
}
</script>

<style lang="scss" scoped>
.timer-container {
    width: 100%;
    min-width: 700px;
    margin: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
}

.header-timer {
    background-color: #e0e0e0;
    padding: 20px 0px;
    text-align: center;
    font-size: 18px;
    font-family: $title-bold;
}

.timer-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    gap: 5px;
}

.bg-success {
    background-color: #157d82;
    padding: 25px;
}

.bg-error {
    background-color: #bd1f1e;
    padding: 25px;
}

.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    position: relative;
    border: 8px solid white;
}

.count {
    font-size: 28px;
    color: #ffffff;
    font-family: $title-bold;
}

.pending-text {
    font-size: 20px;
    line-height: 32px;
    color: white;
    font-family: $title-bold;
}

.container-list {
    padding: 10px;
}

.timer-list {
    padding: 0px 20px 10px 20px;
    min-height: 155px;
    max-height: 240px;
    overflow-y: scroll;
}

#timer-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e2e2e2;
}

#timer-list::-webkit-scrollbar {
    width: 9px;
}

#timer-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b3b3b3;
}

.timer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
}

.task {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    padding-right: 15px;
}

.task-description {
    padding-left: 10px;
    font-family: $text-light;
}

.time {
    font-size: 19px;
    color: #333;
    font-family: $title-bold;
}
.seconds {
    font-size: 16px;
}
.offline {
    padding: 150px 0px !important;
    display: flex;
    justify-content: center;
}

@media (max-width: 900px) {
    .task {
        font-size: 18px;
    }
}
</style>
