<template>
    <div v-if="!isOffline" class="box" :style="{ cursor: url ? 'pointer' : 'default' }" @click="$router.push(url)">
        <div class="count-active" v-if="icon">
            <img :src="icon" alt="box-icon" />
            <label>{{ count }}</label>
        </div>
        <div class="products-discard" v-else>
            {{ count }}
        </div>
        <div class="label">
            {{ label }}
        </div>
    </div>
    <div v-else class="box">
        <img src="/img/offline/no_wifi.svg" />
    </div>
</template>

<script>
export default {
  name: 'SmallWidget',
  props: {
      count: {
          type: Number,
          required: true
      },
      label: {
          type: String,
          required: true
      },
      icon: {
          type: String,
          required: false
      },
      url: {
          type: String,
          required: false
      }
  },
  data() {
      return {
          isOnline: true
      }
  },
  computed: {
    isOffline() {
      return !fnCheckConnection()
    }
  }
}
</script>

<style scoped lang="scss">
.box {
    cursor: pointer;
    width: 320px;
    height: 164px;
}
.products-discard {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid #bd1f1e;
    border-radius: 50%;
    margin: 0px;
    font-size: 24px;
    height: 80px;
    width: 80px;
    font-weight: 600;
    color: #222222;
}
</style>
